<template>
  <div class="advertisement-item">
    <div class="item-row">
      <div class="item-row_name"><span class="symbol">*</span>图片：</div>
      <div class="item-row_container">
        <div v-if="!info.picUrl" class="upload-container">
          <input
            ref="fileInput"
            type="file"
            class="file-input"
            accept="image/*"
            @change="fileChange"
          />
          <i class="el-icon-plus plus-icon"></i>
          <span class="upload-text">上传图片</span>
        </div>

        <el-image v-if="info.picUrl" class="banner" :src="info.picUrl" fit="cover"></el-image>

        <i v-if="info.picUrl" class="el-icon-remove remove-icon" @click="deleteImage"></i>

        <div class="tips">建议尺寸704*180px</div>
      </div>
    </div>

    <div class="item-row">
      <div class="item-row_name"><span class="symbol">*</span>链接：</div>
      <div class="item-row_container">
        <el-input v-model="info.link" size="small" placeholder="请填写跳转链接"></el-input>
      </div>
    </div>

    <div class="item-row">
      <div class="item-row_name"><span class="symbol">*</span>排序：</div>
      <div class="item-row_container">
        <el-input-number v-model="info.sort" :min="1" :max="4" size="small"></el-input-number>
        <div class="tips">仅支持1-4的整数</div>
      </div>
    </div>

    <el-button
      icon="el-icon-delete"
      size="small"
      class="delete-btn"
      @click="deleteItem"
    ></el-button>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: -1,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      info: {
        key: '',
        picUrl: '',
        link: '',
        sort: 1,
        file: null,
      },
    }
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler(newval, _) {
        if (Object.keys(newval || {}).length === 0) return
        this.info = { ...newval }
      },
    },
    info: {
      immediate: false,
      deep: true,
      handler(newval, _) {
        this.$emit('change', { index: this.index, val: { ...newval } })
      },
    },
  },
  methods: {
    fileChange() {
      const fileObj = this.$refs.fileInput.files[0]
      this.info.picUrl = window.URL.createObjectURL(this.$refs.fileInput.files[0])
      this.info.file = fileObj
    },
    deleteImage() {
      this.info.picUrl = ''
    },
    deleteItem() {
      this.$emit('deleteItem', this.index)
    },
  },
}
</script>

<style lang="scss" scoped>
.advertisement-item {
  position: relative;
  background-color: #f7f7f7;
  border: 1px solid #ededed;
  display: inline-flex;
  padding: 20px;
  border-radius: 4px;
  flex-direction: column;

  .item-row {
    display: inline-flex;
    margin-top: 30px;

    &_name {
      font-size: 14px;

      .symbol {
        color: #e86247;
        font-size: 14px;
      }
    }

    &_container {
      position: relative;

      .banner {
        width: 352px;
        height: 90px;
      }

      .remove-icon {
        position: absolute;
        right: -9px;
        top: -9px;
        cursor: pointer;
        color: #e86247;
      }

      .tips {
        color: #9a9d9e;
        font-size: 12px;
        margin-top: 8px;
      }

      .upload-container {
        width: 352px;
        height: 90px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;
        border: 1px dashed #e0e0e0;
        background-color: white;
        position: relative;
        overflow: hidden;

        .file-input {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
        }

        .plus-icon {
          font-size: 30px;
          color: #9a9d9e;
        }

        .upload-text {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }

  .delete-btn {
    position: absolute;
    width: 36px;
    height: 26px;
    right: 8px;
    top: 8px;
    padding: 0;
  }
}
</style>
